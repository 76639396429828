/* Toast Container */
.custom-toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Toast Message Box */
.custom-toast,
.custom-toast-ttm {
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 1px 6px -2px rgba(0, 0, 0, 0.56), 0px 4px 24px -8px rgba(0, 0, 0, 0.16);
    width: 334px;
    padding: 10px;
    overflow: hidden;
    border-radius: 2px 0 2px 2px;
    /* animation: slideIn 0.3s ease-in-out; */
}

/* Toast Border Colors */
.custom-toast.failed,
.custom-toast-ttm.failed {
    border-left: 6px solid #af292e;
}

.custom-toast.unapproved_error,
.custom-toast-ttm.unapproved_error,
.custom-toast-ttm.complete_mismatch {
    border-left: 6px solid #af292e;
}

.custom-toast.partial_success {
    border-left: 6px solid #af292e;
}

.custom-toast.automated,
.custom-toast-ttm.automated {
    border-left: 6px solid #05704b;
}

.custom-toast-ttm.partial_success, .custom-toast-ttm.no_section {
    border-left: 6px solid #eaa14b;
}

/* Toast Header */
.custom-toast-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

/* Toast Icon */
.custom-toast-icon {
    margin-right: 8px;
    align-self: self-start;
}

.custom-toast-icon.automated {
    margin-right: 8px;
    display: flex;
    align-self: stretch;
    align-items: center;
}

/* Toast Message */
.custom-toast-message {
    color: #202935;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 5px;
}

.custom-toast-title.automated,
.custom-toast-title-ttm.automated {
    color: #05704b;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
}

.custom-toast-title.failed,
.custom-toast-title-ttm.failed {
    color: #af292e;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
}

.custom-toast-title.partial_success {
    color: #af292e;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.custom-toast-title.unapproved_error,
.custom-toast-title-ttm.unapproved_error,
.custom-toast-title-ttm.complete_mismatch {
    color: #af292e;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.custom-toast-title-ttm.partial_success, .custom-toast-title-ttm.no_section {
    color: #eaa14b;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

/* Toast Buttons */
.custom-toast-buttons {
    display: flex;
    justify-content: flex-end;
}

.custom-toast-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}

.custom-toast-border {
    border: 1px solid #e6e6e6;
    width: 106%;
    margin-left: -10px;
}

.custom-toast-buttons .dismiss {
    color: #455d82;
}
.custom-toast-buttons .action {
    color: #455d82;
}

/* Animation */
@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
